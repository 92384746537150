//
//
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

// bootstrap

.carousel-item {
  background-color: #f9fafd;
  border-radius: 0.375rem;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: brightness(0) saturate(100%) invert(39%) sepia(74%) saturate(2991%) hue-rotate(201deg)
    brightness(96%) contrast(86%);
}

.carousel-indicators {
  margin-bottom: 0;
}

.carousel-indicators li {
  background-color: #2c7be5;
}

// react-datepicker

.react-datepicker-wrapper {
  display: block;
}

// react-notion-x

:root {
  --notion-max-width: auto;
}

.notion-page {
  width: auto !important;
  margin: 0 !important;
  padding: 0 !important;
}

.notion-viewport {
  z-index: -1;
}

.notion-collection {
  width: 100%;
  align-self: auto;
}

.notion-collection-header {
  padding: 0 !important;
}

.notion-table {
  width: 100% !important;
  overflow: scroll auto;
}

.notion-table-view {
  padding: 0 !important;
}

// form required

.form-group label.required::after {
  content: ' *';
  color: $red;
}
